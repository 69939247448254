.Blocks{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    animation-delay: 1.3s;
    opacity: 1;
}
.Block{
    overflow-x: scroll;
    flex:2;
    height: 15rem;
    background-color: rgba(0,0,0,0.3);
    border-radius: 1rem;
    padding: 0.5rem;
}
.Block::-webkit-scrollbar{
    display: none;
}
.Chain{
    flex:1;
    height: 0.3rem;
    background-color: rgba(0,0,0,0.6);
}
.BlockTitle{
    color: rgba(255,255,255,0.6);
    font-size: 1rem;
    font-weight: bold;
    justify-content: center;
    text-align: center;
    display: flex;
    align-items: center;
}
.BlockInfo{
    font-weight: bold;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 1rem;
    overflow-x: clip;
    overflow-y: scroll;
    word-break: break-all;
    text-overflow: fade;
    padding: 0.5rem;
    border: none;
    background-color: rgba(255,255,255,0.4);
    color: rgba(20,20,20,0.8);
}

.Block:hover{
    scale: 1.1;
    background-color: rgba(50,50,50,1);
}

h2{
    font-size: larger;
    color: rgba(255,255,255,0.8);
}
.container {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: #f0f0f0;
}

.BlockInfo::-webkit-scrollbar {
    display: none;
}
.Filter{
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: space-evenly;
    margin-bottom: 2rem;
    flex-direction: row;
    font-weight: bold;
}
select{
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    flex:1;
    border-radius: 1rem;
    border: none;
    display: flex;
    padding: 1rem;
    margin-bottom: 0.5rem;
}
option{
    font-weight: bold;
}
@keyframes up {
    0% {
        opacity: 0;
        transform: translateY(10%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
.Card{
    width: 80%;
    margin-bottom: 1.5rem;
    padding: 1rem;
    background-color: #1e1e1e;
    animation: up 0.5s ease-in-out;
    color: #f0f0f0;
    border-radius: 1rem;
    transition: ease-in-out;
}
.CardContent{
    display: flex;
    font-weight: bold;
    flex-direction: column;
}
.Card-Day{
    margin: 0.1rem;
}
.Card-Type{
    margin: 0.1rem;
}
.Card-Author{
    margin: 0.1rem;
}
.Card-Content{
    margin: 0.1rem;
}
.More-Info{
    margin: 0.1rem;

}
a{
    color: cornflowerblue;
    text-decoration: none;
}