@tailwind base;
@tailwind components;
@tailwind utilities;


@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: rgba(27, 26, 25,0.7);
}

main{
  padding: 1.5rem;
}
::-webkit-scrollbar {
  /*display: none;*/
  width: 0.5rem;
  border-radius: 1rem;
}

::-webkit-scrollbar-button:no-button {
}
::-webkit-scrollbar-track-piece {
  background-color: darkgray;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255,255,255);
  border-radius: 1rem;
}
footer{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-content: center;
  justify-content: center;
  padding-top: 2rem;
  align-items: center;
  padding-bottom: 2rem;
  margin-bottom:1.5rem;
  font-weight: bold;
  border-radius: 1rem;
  background-color: darkgray;
}
.Contact{
  font-weight: bold;
  font-size: x-large;
  color: white;
}
.RouterBtn{
  font-weight: bold;
  color: #f0f0f0;
}
.falling-text {
  margin-top: 1.5rem;
  color: rgba(255, 255, 255, 0.7);
  font-weight: bold;
  font-size: 36px;
  text-align: center;
  align-items: center;
}

.falling-text span {
  display: inline-block;
  opacity: 0;
  animation: fall 1s forwards;
  animation-delay: calc(0.3s * var(--i));
}

@keyframes fall {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.falling-text span:nth-child(1) {
  --i: 1;
}

.falling-text span:nth-child(2) {
  --i: 2;
}

.falling-text span:nth-child(3) {
  --i: 3;
}
.falling-text span:nth-child(4) {
  --i: 4;
}
.falling-text span:nth-child(5) {
  --i: 5;
}
.AppBar{
  width: 90%;
  border-radius: 1rem;
  padding: 1.5rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: end;
  background-color: #282c34;
}
.AppBar-div{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.RealMain{
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding-top: 0rem;
  align-items: center;
}